import {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  MouseEvent,
  useRef,
  forwardRef,
  useEffect,
  KeyboardEventHandler,
  ClipboardEventHandler,
} from "react";
import styles from "../InputField/InputField.module.scss";
import { Icon } from "../Icon/icon";
import { StatusText } from "../StatusText/StatusText";

interface TextEditorProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  value?: string;
  label: string;
  hideLabel?: boolean;
  appendLabel?: string | ReactNode;
  className?: string;
  disabled?: boolean;
  focus?: boolean;
  icon?: string;
  prepend?: ReactNode;
  append?: ReactNode;
  large?: boolean;
  danger?: boolean;
  success?: boolean;
  dark?: boolean;
  required?: boolean;
  maxLength?: number;
  rows?: number;
  allowNewLines?: boolean;
  /**
   * Automatically adjust the height of the textarea based on its content
   * @default true
   */
  autoResize?: boolean;
  /**
   * Used in combination with autoResize to allow the textarea to grow up to a certain height
   */
  maxHeight?: number;
  compact?: boolean;
  /**
   * Background color of the input field (any CSS color value)
   * @WARNING If "onClear" or "append" props are used, the color must be a HEX value
   */
  inputBgColor?: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  dangerText?: string;
  successText?: string;
  infoText?: string;
}

export const TextEditor = forwardRef<HTMLDivElement, TextEditorProps>(
  function TextEditor(
    {
      value,
      label,
      hideLabel,
      appendLabel,
      className,
      disabled,
      focus,
      icon,
      prepend,
      append,
      large,
      danger,
      success,
      dark,
      required,
      maxLength,
      rows,
      allowNewLines = true,
      autoResize = true,
      maxHeight,
      compact,
      inputBgColor,
      onChange,
      onClear,
      dangerText,
      successText,
      infoText,
      ...rest
    }: TextEditorProps,
    ref,
  ) {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const adjustHeight = () => {
      const textarea = inputRef.current;
      if (textarea && autoResize) {
        textarea.style.height = "auto"; // Reset height to recalculate
        textarea.style.height = `${maxHeight ? Math.min(textarea.scrollHeight, maxHeight) : textarea.scrollHeight}px`;
        textarea.style.overflowY =
          maxHeight && textarea.scrollHeight > maxHeight ? "auto" : "hidden";
      }
    };

    const clearInputField = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (onClear) onClear(e);
    };

    const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
      if (!allowNewLines && e.key === "Enter") {
        e.preventDefault(); // Prevent new lines
      }
    };

    const handlePaste: ClipboardEventHandler = (e) => {
      if (allowNewLines) return;

      e.preventDefault();
      const textarea = inputRef.current;
      if (!textarea) return;

      // Get pasted text and remove newlines
      const pastedText =
        e.clipboardData?.getData("text/plain").replace(/\n/g, " ") || "";

      // Insert text at the current cursor position
      const { selectionStart, selectionEnd } = textarea;
      textarea.setRangeText(pastedText, selectionStart, selectionEnd, "end");

      const event = new Event("input", { bubbles: true });
      textarea.dispatchEvent(event);
    };

    useEffect(adjustHeight, [value]);

    return (
      <div className={`${styles.inputContainer} ${dark ? styles.dark : ""}`}>
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "end",
            justifyContent: "flex-start",
          }}
        >
          <label
            htmlFor={`${label}_TEXTFIELD`}
            className={hideLabel || (compact && hideLabel) ? "sr-only" : ""}
          >
            {label}
            {required && typeof label === "string" && (
              <>
                {" "}
                <strong aria-hidden>*</strong>
                <span className="sr-only">(required)</span>
              </>
            )}
          </label>
          {!!appendLabel && appendLabel}
        </div>
        <div
          ref={ref}
          className={`
        ${className ? className : ""} 
        ${styles.inputField} 
        ${focus ? styles.focus : ""}
        ${large ? styles.large : ""}
        ${danger || (dangerText && dangerText.length > 0) ? styles.danger : ""}
        ${
          success || (successText && successText.length > 0)
            ? styles.success
            : ""
        }
        ${dark ? styles.dark : ""}
        ${disabled ? styles.disabled : ""}`}
          {...(inputBgColor
            ? { style: { backgroundColor: inputBgColor } }
            : {})}
          onClick={() => inputRef.current?.focus()}
        >
          {icon && <Icon className={styles.icon} name={icon} />}
          {!!prepend && prepend}
          <textarea
            {...rest}
            ref={inputRef}
            id={`${label}_TEXTFIELD`}
            value={value}
            className={`${styles.input} ${styles.simpleEditor} ${dark ? styles.dark : ""}`}
            onChange={(e) => {
              if (maxLength && e.target.value.length > maxLength) return;
              else onChange(e);
            }}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            rows={rows || 1}
            style={{
              ...rest.style,
              marginTop: 4,
              marginBottom: 4,
              ...(onClear || append ? { marginRight: 54 } : {}),
            }}
          ></textarea>
          {(!!onClear || !!append) && (
            <div
              className={styles.appendContainer}
              {...(inputBgColor
                ? {
                    style: {
                      background: `linear-gradient(to right, ${inputBgColor}00, ${inputBgColor}FF, ${inputBgColor}FF, ${inputBgColor}FF)`,
                    },
                  }
                : {})}
            >
              {!!onClear && (
                <button
                  className={styles.clearBtn}
                  onClick={(e) => {
                    if (!value) return;
                    clearInputField(e);
                  }}
                  style={{
                    ...(value ? { display: "flex" } : { display: "none" }),
                    ...(dark ? { color: "white" } : {}),
                  }}
                >
                  <Icon name="close" />
                  <span className="sr-only">Clear</span>
                </button>
              )}
              {!!append && append}
            </div>
          )}
        </div>
        <div className={styles.bottomContainer}>
          {!dangerText && !successText && !infoText && !compact ? (
            <div style={{ height: "24px" }}></div>
          ) : dangerText ? (
            <StatusText text={dangerText} fail dark={dark} />
          ) : successText ? (
            <StatusText text={successText} success dark={dark} />
          ) : (
            !!infoText && <StatusText text={infoText} dark={dark} />
          )}
          {!!maxLength && (
            <span className={styles.maxLength}>
              {value ? value.length : 0} / {maxLength}
            </span>
          )}
        </div>
      </div>
    );
  },
);
