/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CustomAxiosError,
  DateString,
  PreRegistrationGroup,
  Timestamp,
  TimeString,
} from "@/types";
import { SortingFn } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { addMinutes, isAfter, isBefore } from "date-fns";
import { format } from "date-fns-tz";
import { Event } from "react-big-calendar";

export const hasCustomErrorCode = (
  error: unknown,
): error is CustomAxiosError => {
  return (
    typeof error === "object" &&
    error !== null &&
    "isAxiosError" in error && // Axios-specific flag
    (error as AxiosError<CustomAxiosError>).response?.data?.code !== undefined // Ensure `code` exists
  );
};

export const NOK = Intl.NumberFormat("nb-NO", {
  style: "currency",
  currency: "NOK",
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

const FULL_DATE_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};
const FULL_DATE_LOCALE = (lang: string) =>
  new Intl.DateTimeFormat(
    lang === "en" ? "en-GB" : "nb-NO",
    FULL_DATE_LOCALE_OPTIONS,
  );

const DATE_DAY_MONTH_OPTIONS: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
  timeStyle: undefined,
};

export const DATE_DAY_MONTH_LOCALE = (lang: string) =>
  new Intl.DateTimeFormat(
    lang === "en" ? "en-GB" : "nb-NO",
    DATE_DAY_MONTH_OPTIONS,
  );

const DATE_TIME_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

export const DATE_TIME_LOCALE = (lang: string) =>
  new Intl.DateTimeFormat(
    lang === "en" ? "en-GB" : "nb-NO",
    DATE_TIME_LOCALE_OPTIONS,
  );

const BOOKING_DATE_LOCALE_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "long",
};
export const BOOKING_DATE_LOCALE = (lang: string) =>
  new Intl.DateTimeFormat(
    lang === "en" ? "en-GB" : "nb-NO",
    BOOKING_DATE_LOCALE_OPTIONS,
  );

export const firebaseTimestampToDate = (timestamp: Timestamp): Date => {
  return new Date(timestamp._seconds * 1000);
};

export const formatTimestampToTimeAgo = (
  t: (key: any, values?: any) => any,
  timestamp: Timestamp,
  lang: string = "no",
  withYear?: boolean,
): string => {
  if (!timestamp._seconds) {
    return "Invalid timestamp";
  }

  const currTime = new Date();
  const date = firebaseTimestampToDate(timestamp);
  const diffInMinutes = Math.floor(
    (currTime.getTime() - date.getTime()) / 60000,
  );

  if (diffInMinutes <= 0) {
    return t("JUST_NOW");
  } else if (diffInMinutes < 60) {
    return t("MINUTES_AGO", { count: diffInMinutes });
  } else if (withYear) {
    if (currTime.getFullYear() !== date.getFullYear()) {
      return FULL_DATE_LOCALE(lang).format(date);
    } else {
      return DATE_TIME_LOCALE(lang).format(date);
    }
  } else {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
};

export const timestampSortingFn: SortingFn<any> = (rowA, rowB, columnId) => {
  const getSortableTuple = (value: any): [number, number] => {
    if (typeof value === "object" && value?._seconds !== undefined) {
      return [1, value._seconds]; // [validity flag, actual seconds]
    }
    return [0, 0]; // Invalid timestamps: [invalid flag, zero or low priority value]
  };

  const [aValidFlag, aSeconds] = getSortableTuple(rowA.getValue(columnId));
  const [bValidFlag, bSeconds] = getSortableTuple(rowB.getValue(columnId));

  // Sort by validity first, then by the timestamp value
  if (aValidFlag !== bValidFlag) {
    return bValidFlag - aValidFlag; // Invalid (0) should come before valid (1)
  }
  return aSeconds - bSeconds;
};

export const longNumberStringSortingFn: SortingFn<any> = (
  rowA,
  rowB,
  columnId,
) => {
  const parseValue = (value: any): bigint => {
    if (value === "" || value == null || value === undefined) return 0n; // Treat empty/null/undefined as 0
    try {
      return BigInt(value);
    } catch {
      return 0n; // Fallback for invalid values
    }
  };

  const aValue = parseValue(rowA.getValue(columnId));
  const bValue = parseValue(rowB.getValue(columnId));

  return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
};

export const env = (key: string) => {
  const value = import.meta.env[key];
  if (!value) throw new Error(`Env ${key} not found`);
  return value;
};

export const validatePhoneNumber = (number: string) => {
  if (isNorwegianNumber(number)) {
    const cleanedNumber = cleanPhoneNumber(number);
    if (
      (cleanedNumber.includes("+47") && cleanedNumber.length === 11) ||
      (!cleanedNumber.includes("+47") && cleanedNumber.length === 8)
    ) {
      return true;
    } else {
      return false;
    }
  }

  if (number.length >= 8 && number.includes("+")) {
    return true;
  }

  return false;
};

export const cleanPhoneNumber = (number: string) => {
  if (!number) {
    return "";
  }
  return number.replace(/[^\d+]/g, "");
};

export const isNumberMissingCountryCode = (number: string) => {
  //this is only needed for foreign numbers. We can only assume that numbers with more than 8 digits are foreign.
  return number.length > 8 && !number.includes("+");
};

export const isNorwegianNumber = (number: string) => {
  return (
    number.substring(0, 3) === "+47" ||
    (!number.includes("+") && number.length == 8)
  );
};

export const addNorwegianCountryCodeToNorwegianNumbers = (number: string) => {
  if (isNorwegianNumber(number)) {
    if (!number.includes("+47")) {
      return "+47" + number;
    }
  }
  return number;
};

export const findMatchingCountryCode = (
  countryCodes: {
    code: string;
    dialCode: string;
  }[],
  phone?: string,
) => {
  if (!phone || phone.length < 4) return "";
  // Sort country codes by length (longest first)
  const sortedCountries = countryCodes.sort(
    (a, b) => b.dialCode.length - a.dialCode.length,
  );
  return (
    sortedCountries.find((c) => phone.startsWith(c.dialCode))?.dialCode || ""
  );
};

export const downloadArrayAsCSV = (items: any[], filename = "data") => {
  const replacer = (_: string, value: any) => (value === null ? "" : value);
  const header = Object.keys(items[0]);
  const csv = [
    header.join(","),
    ...items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(","),
    ),
  ].join("\r\n");
  downloadFile(new Blob([csv], { type: "text/csv" }), `${filename}.csv`);
};

export const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.style.display = "none";
  a.click();
  window.URL.revokeObjectURL(url);
};

export const capitalize = (s?: string) =>
  (s && String(s[0]).toUpperCase() + String(s).slice(1)) || "";

const LONG_WEEKDAY_LOCALE = (lang: string) => {
  return new Intl.DateTimeFormat(lang === "en" ? "en-GB" : "nb-NO", {
    weekday: "long",
  });
};
export const ALL_WEEKDAYS = [1, 2, 3, 4, 5, 6, 0];
export const getWeekdayFromNumber = (weekday: number, lang: string = "no") => {
  if (weekday < 0 || weekday > 6) return "Invalid weekday";
  return capitalize(LONG_WEEKDAY_LOCALE(lang).format(new Date(0, 0, weekday)));
};
export const formatOpenOnDays = (
  closedOnDays: number[],
  lang: string = "no",
  t: (key: any, values?: any) => any,
) => {
  if (closedOnDays.length === 7) {
    return "Invalid opening hours";
  }

  if (closedOnDays.length === 0) {
    return t("ALL_DAYS").toLowerCase();
  }

  if (
    closedOnDays.length === 2 &&
    closedOnDays.includes(0) &&
    closedOnDays.includes(6)
  ) {
    // Closed on weekends (return "Monday - Friday")
    return `${getWeekdayFromNumber(1, lang)} - ${getWeekdayFromNumber(5, lang)}`;
  }
  const openOnDays = ALL_WEEKDAYS.filter((day) => !closedOnDays.includes(day));

  // Order days from 1 (monday) to 6 (saturday), then 0 (sunday)
  openOnDays.sort((a, b) => (a === 0 ? 7 : a) - (b === 0 ? 7 : b));

  // Group consecutive days
  const groups: number[][] = [];
  let tempGroup: number[] = [openOnDays[0]];

  for (let i = 1; i < openOnDays.length; i++) {
    const prev = openOnDays[i - 1] === 0 ? 7 : openOnDays[i - 1]; // Treat Sunday (0) as 7
    const current = openOnDays[i] === 0 ? 7 : openOnDays[i];

    if (current === prev + 1) {
      tempGroup.push(openOnDays[i]);
    } else {
      groups.push(tempGroup);
      tempGroup = [openOnDays[i]];
    }
  }
  groups.push(tempGroup);

  // Format groups (only as ranges if 3+ days)
  const formattedDays = groups.map((group) => {
    if (group.length >= 3) {
      return `${getWeekdayFromNumber(group[0], lang)} - ${getWeekdayFromNumber(group[group.length - 1], lang)}`;
    } else {
      return group.map((day) => getWeekdayFromNumber(day, lang)).join(", ");
    }
  });

  // Replace last comma with " & " if there are multiple groups
  return formattedDays.length > 1
    ? formattedDays.slice(0, -1).join(", ") + " & " + formattedDays.at(-1)
    : formattedDays[0];
};

export const formatFloorNumber = (
  floor: string | number = 0,
  t: (key: any) => any,
): string => {
  const floorNum = Number(floor);
  if (floorNum < 0) {
    return `${t("FLOOR")} ${floor.toString().replace("-", "U")}`;
  } else {
    return `${floor}. ${t("FLOOR").toLowerCase()}`;
  }
};

export const formatTimestampPreregistration = (
  timestamp: Timestamp,
  t: (key: any, values?: any) => any,
  lang: string = "no",
) => {
  if (!timestamp._seconds) {
    return "Invalid timestamp";
  }

  const currTime = new Date();
  const date = firebaseTimestampToDate(timestamp);

  const currYear = currTime.getFullYear();
  const currDay = currTime.getDate();
  const currMonth = currTime.getMonth();

  const targetYear = date.getFullYear();
  const targetDay = date.getDate();
  const targetMonth = date.getMonth();

  const isToday =
    targetYear === currYear &&
    targetMonth === currMonth &&
    targetDay === currDay;

  const isTomorrow =
    targetYear === currYear &&
    targetMonth === currMonth &&
    targetDay === currDay + 1;

  const timeString = date.toLocaleTimeString(
    lang === "en" ? "en-GB" : "nb-NO",
    { timeStyle: "short" },
  );

  if (isToday) {
    return `${t("TODAY")}, ${t("AT")} ${timeString}`;
  } else if (isTomorrow) {
    return `${t("TOMORROW")}, ${t("AT")} ${timeString}`;
  } else if (targetYear === currYear) {
    return DATE_TIME_LOCALE(lang).format(date);
  } else {
    return FULL_DATE_LOCALE(lang).format(date);
  }
};

export const formatTimestampToHoursAndMinutes = (timestamp: Timestamp) => {
  if (!timestamp._seconds) {
    return "Invalid timestamp";
  }
  const date = firebaseTimestampToDate(timestamp);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const filterTodayPreregistrations = (
  preregistrations: PreRegistrationGroup[],
) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return preregistrations.filter((preregistration) => {
    const preregistrationDate = firebaseTimestampToDate(
      preregistration.startTime,
    );
    preregistrationDate.setHours(0, 0, 0, 0);
    return preregistrationDate.getTime() === today.getTime();
  });
};

export const totalUsersSortFn: SortingFn<any> = (rowA, rowB, columnId) => {
  // Helper function to extract totalUsers with a fallback for invalid data
  const getSortableTuple = (value: any): [number, number] => {
    if (Array.isArray(value) && typeof value[1] === "number") {
      return [1, value[1]]; // [validity flag, totalUsers value]
    }
    return [0, 0]; // Invalid data: [invalid flag, zero priority value]
  };

  const [aValidFlag, totalUsersA] = getSortableTuple(rowA.getValue(columnId));
  const [bValidFlag, totalUsersB] = getSortableTuple(rowB.getValue(columnId));

  // Sort by validity first, then by the totalUsers value
  if (aValidFlag !== bValidFlag) {
    return bValidFlag - aValidFlag; // Invalid (0) comes before valid (1)
  }
  return totalUsersA - totalUsersB;
};

export const formatDateToTimezonedString = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssXXX", {
    timeZone: "Europe/Oslo",
  });
};

export const isDateString = (value: string): value is DateString => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(value);
};

export const dateToDateInputString = (date: Date): DateString => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}` as DateString;
};

export const dateInputStringToDate = (date: DateString): Date => {
  const [year, month, day] = date.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const dateToTimeInputString = (date: Date): TimeString => {
  return date.toLocaleTimeString("nb-NO", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }) as TimeString;
};

export const timeInputStringToDate = (
  time: TimeString,
  onDate?: Date | DateString,
): Date => {
  const [hours, minutes] = time.split(":").map(Number);
  const date = onDate
    ? onDate instanceof Date
      ? new Date(onDate)
      : dateInputStringToDate(onDate)
    : new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};

export const calculateEndTime = (
  startTime: TimeString,
  duration: number,
  onDate?: Date | DateString,
): Date => {
  const [hours, minutes] = startTime.split(":");

  const startTimeAsDate = onDate ? new Date(onDate) : new Date();
  startTimeAsDate.setHours(Number(hours));
  startTimeAsDate.setMinutes(Number(minutes));

  return addMinutes(startTimeAsDate, duration * 60);
};

export const isSlotOverlapping = (
  bookedSlots: Event[],
  newSlot: { start: Date; end: Date },
): boolean => {
  return bookedSlots.some((slot) => {
    return (
      !!slot.end &&
      !!slot.start &&
      // New slot starts before the existing slot ends, but not exactly at the end
      isBefore(newSlot.start, slot.end) &&
      // New slot ends after the existing slot starts, but not exactly at the start
      isAfter(newSlot.end, slot.start)
    );
  });
};

export const debounceAsync = <T extends (...args: any[]) => Promise<any>>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => Promise<ReturnType<T>>) => {
  let timeoutId: NodeJS.Timeout;
  let resolveList: ((value: ReturnType<T>) => void)[] = [];

  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    clearTimeout(timeoutId);

    return new Promise<ReturnType<T>>((resolve) => {
      resolveList.push(resolve);

      timeoutId = setTimeout(async () => {
        const result = await func(...args);
        resolveList.forEach((res) => res(result));
        resolveList = [];
      }, delay);
    });
  };
};

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatAccessRoleName(str: string): string {
  return capitalizeFirstLetter(str.split("_").join(" "));
}

export function getTimestampOutOfString(timestamp: string): Timestamp {
  const date = new Date(timestamp);
  return {
    _seconds: Math.floor(date.getTime() / 1000),
    _nanoseconds: (date.getTime() % 1000) * 1e6,
  };
}

export const discountMultiplierToPercentage = (multiplier: number) =>
  `${Math.round((1 - multiplier) * 100)}%`;

export const percentageToDiscountMultiplier = (percentage: string) =>
  1 - parseFloat(percentage) / 100;

export function isInvalidPin(pin: string): {
  isInvalid: boolean;
  errorCodes?: string[];
} {
  const errors: string[] = [];

  // Rule 1: Ensure the PIN is exactly 4 digits
  if (pin.length !== 4) errors.push("INVALID_LENGTH");

  // Rule 2: No repeated digits (e.g., 1111)
  if (/^(.)\1{3,}$/.test(pin)) errors.push("REPEATED_DIGITS");

  // Rule 3: No consecutive numbers (ascending or descending)
  const ascending = "0123456789";
  const descending = "9876543210";
  if (ascending.includes(pin) || descending.includes(pin))
    errors.push("CONSECUTIVE_NUMBERS");

  // Rule 4: No straight line keypad patterns
  const keypadPatterns = ["2580", "0852"];
  if (keypadPatterns.includes(pin)) errors.push("KEYPAD_PATTERN");

  return { isInvalid: errors.length > 0, errorCodes: errors };
}
