import {
  useQuery,
  UseQueryResult,
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";
import {
  ExtendedCompany,
  Company,
  ExtendedUser,
  CompanyContact,
  SSOItem,
  BronnoysundCompanyResult,
} from "@/types";
import { ORG_NUMBER_REGEX } from "@/lib/constants";

export const useCompanyContact = (
  buildingTenantId?: string,
): UseSuspenseQueryResult<CompanyContact | null> => {
  return useSuspenseQuery({
    queryKey: ["company-contact", buildingTenantId],
    queryFn: async () => {
      try {
        if (!buildingTenantId) throw new Error("buildingTenantId is required");
        const response = await client.get(
          `/getCompanyContact?buildingTenantId=${buildingTenantId}`,
        );
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  });
};

export const companyAdminsOptions = (buildingTenantId?: string) => ({
  queryKey: ["company-admins", buildingTenantId],
  queryFn: async () => {
    try {
      if (!buildingTenantId) throw new Error("buildingTenantId is required");
      const response = await client.get(
        `/getCompanyAdmins?buildingTenantId=${buildingTenantId}`,
      );
      return response.data.users;
    } catch (error) {
      throwCaughtError(error);
    }
  },
});

export const useCompanyAdmins = (
  buildingTenantId?: string,
): UseSuspenseQueryResult<ExtendedUser[]> => {
  return useSuspenseQuery(companyAdminsOptions(buildingTenantId));
};

export const getCompanyFunction = async (
  buildingTenantId: string,
): Promise<Company | undefined> => {
  try {
    const response = await client.get(
      `/getCompanyById?buildingTenantId=${buildingTenantId}`,
    );
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
  return undefined;
};

export const companyOptions = (buildingTenantId: string) => ({
  queryKey: ["company", buildingTenantId],
  queryFn: () => getCompanyFunction(buildingTenantId),
});

export const useCompany = (
  buildingTenantId: string | undefined = "",
): UseSuspenseQueryResult<Company> => {
  return useSuspenseQuery({
    ...companyOptions(buildingTenantId),
    staleTime: 1000 * 60 * 60, // 1 hour
  });
};

export const useCompaniesNames = (
  buildingTenantIds: string[] | undefined = [],
): UseSuspenseQueryResult<Partial<Company>[]> => {
  return useSuspenseQuery({
    queryKey: ["companies-names", buildingTenantIds],
    queryFn: async () => {
      if (buildingTenantIds.length === 0) return [];
      try {
        const companyIdsAsQueryParams = buildingTenantIds
          .map((id, index) =>
            index === 0
              ? `buildingTenantIds=${id}`
              : `&buildingTenantIds=${id}`,
          )
          .join("");
        const response = await client.get(
          `/getCompaniesNames?${companyIdsAsQueryParams}`,
        );
        return response.data;
      } catch (error) {
        throwCaughtError(error);
      }
    },
  });
};

export const useCompaniesOrgNr = (
  buildingTenantIds: string[] | undefined = [],
): UseQueryResult<Partial<Company>[]> => {
  return useQuery({
    queryKey: ["companies-org-nr", buildingTenantIds],
    queryFn: async () => {
      if (buildingTenantIds.length === 0) return [];
      try {
        const companyIdsAsQueryParams = buildingTenantIds
          .map((id, index) =>
            index === 0
              ? `buildingTenantIds=${id}`
              : `&buildingTenantIds=${id}`,
          )
          .join("");
        const response = await client.get(
          `/getCompaniesOrgNr?${companyIdsAsQueryParams}`,
        );
        return response.data;
      } catch (error) {
        throwCaughtError(error);
      }
    },
  });
};

export const extendedCompaniesOptions = {
  queryKey: ["extended-companies"],
  queryFn: async () => {
    try {
      const response = await client.get("/getExtendedCompanies");
      return response.data;
    } catch (error) {
      throwCaughtError(error);
    }
  },
};

export const useExtendedCompanies = (): UseSuspenseQueryResult<
  ExtendedCompany[]
> => {
  return useSuspenseQuery(extendedCompaniesOptions);
};

// Documentation: https://data.brreg.no/enhetsregisteret/api/dokumentasjon/no/index.html#tag/Enheter/operation/hentEnheter
export const getCompaniesFromBronnoysund = async (
  query: string,
): Promise<BronnoysundCompanyResult[] | undefined> => {
  try {
    if (!query) return;
    const queryIsOrgNumber = ORG_NUMBER_REGEX.test(query);
    const queryParams =
      (queryIsOrgNumber ? "organisasjonsnummer=" : "navn=") +
      encodeURIComponent(query);
    const res = await fetch(
      `https://data.brreg.no/enhetsregisteret/api/enheter?${queryParams}&size=5`,
    );
    const data = await res.json();
    return data?._embedded?.enheter || [];
  } catch (error) {
    throwCaughtError(error);
  }
};

export const createCompanyInBuilding = async (
  companyData: Partial<Company>,
) => {
  return await client.post("/createCompany", { companyData });
};

export const updateCompanySettings = async (
  buildingTenantId: string,
  updateData: Partial<Company>,
) => {
  return await client.patch(`/updateCompany`, {
    buildingTenantId,
    updateData,
  });
};

export const deleteCompany = async (companyId: string) => {
  return await client.delete("/deleteCompany", {
    data: { buildingTenantId: companyId },
  });
};

export const addSsoToCompany = async (
  buildingTenantId: string,
  companyName: string,
  realEstate: string,
  ssoSettings: SSOItem[],
) => {
  return await client.post("/addSso", {
    buildingTenantId,
    tenantName: companyName,
    realEstate,
    ssoSettings,
  });
};
