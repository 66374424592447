import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";
import { AddUserType, ConsentType, User } from "@/types";
import { downloadFile, env } from "@/lib/utils";

export const useUserOptions = (userId: string, buildingTenantId?: string) => ({
  queryKey: ["user", userId],
  queryFn: async () => {
    try {
      const response = await client.get(
        `/getUser?buildingTenantId=${buildingTenantId}&field=userId&value=${userId}`,
      );
      return response.data.user;
    } catch (error) {
      throwCaughtError(error);
    }
  },
  enabled: !!userId && !!buildingTenantId,
});

export const useUser = (
  userId: string,
  buildingTenantId?: string,
): UseSuspenseQueryResult<User> => {
  return useSuspenseQuery(useUserOptions(userId, buildingTenantId));
};

export const userMetadataOptions = (
  userIds: string[],
  buildingTenantId?: string,
) => ({
  queryKey: ["user-metadata", userIds],
  queryFn: async () => {
    try {
      if (!buildingTenantId) throw new Error("buildingTenantId is required");
      const response = await client.post(`/getCreatedAtAndLastLogin`, {
        buildingTenantId,
        userIds,
      });
      return response.data.users;
    } catch (error) {
      throwCaughtError(error);
    }
  },
});

export const checkUserLogin = async (email: string) => {
  const response = await fetch(
    `${env("VITE_API_BASE")}/api/getUserLogin?email=${email}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
  return response;
};

export const companyUsersOptions = (buildingTenantId?: string) => ({
  queryKey: ["users", buildingTenantId],
  queryFn: async () => {
    try {
      if (!buildingTenantId) throw new Error("buildingTenantId is required");
      const response = await client.get(
        `/getUsersByBuildingTenantId?buildingTenantId=${buildingTenantId}`,
      );
      return response.data.users;
    } catch (error) {
      throwCaughtError(error);
    }
  },
});

export const useCompanyUsers = (
  buildingTenantId?: string,
): UseSuspenseQueryResult<User[]> => {
  return useSuspenseQuery(companyUsersOptions(buildingTenantId));
};

export const getExportedCompanyUsers = async (
  companyId: string,
  companyName?: string,
): Promise<File | undefined> => {
  try {
    const response = await client.get(
      `/exportUsersCsv?buildingTenantId=${companyId}`,
      { responseType: "blob" },
    );
    const blob = new Blob([response.data], { type: "text/csv" });
    const filename = `${(companyName || companyId).split(" ").join("_")}_users_export.csv`;
    downloadFile(blob, filename);
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getExportedBuildingUsers = async (
  buildingName: string | undefined,
): Promise<File | undefined> => {
  try {
    const response = await client.get("/exportAllUsersCsv", {
      responseType: "blob",
    });
    const blob = new Blob([response.data], { type: "text/csv" });
    const filename = buildingName
      ? `${buildingName.split(" ").join("_")}_all_users_export.csv`
      : "all_users_export.csv";
    downloadFile(blob, filename);
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getUser = async (
  field?: string,
  value?: string,
  companyId?: string,
): Promise<User | undefined> => {
  try {
    const response = await client.get(
      `/getUser?field=${field}&value=${value}` +
        (companyId ? `&companyId=${companyId}` : ""),
    );
    return response.data.user;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getUserNamesOptions = (isAdmin: boolean, userIds: string[]) => {
  return {
    queryKey: ["get-user-ids", userIds],
    queryFn: async () => {
      if (!isAdmin) return {};
      try {
        const deduplicated = [...new Set(userIds)];

        const response = await client.post(`/getUserNames`, {
          userIds: deduplicated,
        });
        const userMap: { [key: string]: string } = {};
        response.data.users.forEach(
          (user: { userId: string; userName: string }) => {
            userMap[user.userId] = user.userName;
          },
        );
        return userMap;
      } catch (error) {
        console.log(error);
        return {};
      }
    },
  };
};

export const getUserByEmail = async (
  email: string,
  companyId?: string,
): Promise<User | undefined> => {
  try {
    const response = await client.get(
      `/getUserByEmail?email=${email}` +
        (companyId ? `&companyId=${companyId}` : ""),
    );
    return response.data.user;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const createUsers = async (
  users: AddUserType[],
  tenantAdminName: string,
  tenantName: string,
  buildingName: string,
  visitorRegistrationEnabled: boolean,
  invitingAdminTenantName: string,
  buildingTenantId: string,
) => {
  return await client.post("/createUser", {
    users,
    tenantAdminName,
    tenantName,
    buildingName,
    visitorRegistrationEnabled,
    invitingAdminTenantName,
    buildingTenantId,
  });
};

export const updateUser = async (
  userId: string,
  updateData: Partial<User>,
  oldEmail?: string,
  buildingTenantId?: string,
) => {
  return await client.patch("/updateUser", {
    userId,
    updateData,
    oldEmail,
    buildingTenantId,
  });
};

export const updateConsent = async (
  userId: string,
  consents: { type: ConsentType; consented: boolean }[],
) => {
  return await client.patch("/updateConsent", {
    userId,
    consents,
  });
};

export const deleteUser = async (
  userId: string,
  email: string,
  buildingTenantId: string,
) => {
  return await client.delete("/deleteUser", {
    data: { userId, email, buildingTenantId },
  });
};

export const sendMagicLink = async (email: string, language: string) => {
  const body = JSON.stringify({ userEmail: email, language });
  try {
    const response = await fetch(
      `${env("VITE_API_BASE")}/api/sendMagicLinkEmail`,
      {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
    return response;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const logoutFromKeycloak = async (email: string) => {
  try {
    const response = await client.post("/logoutFromKeycloak", { email });
    return response;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const checkExistingUser = async (
  email: string,
  buildingTenantId: string,
) => {
  const encodedEmail = encodeURIComponent(email);
  try {
    const response = await client.get(
      `/checkIfUserExists?email=${encodedEmail}&buildingTenantId=${buildingTenantId}`,
    );
    return response.data.userExist;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const checkExistingEmails = async (
  newEmails: string[],
  buildingTenantId: string,
) => {
  try {
    const response = await client.post("/checkExistingEmails", {
      newEmails,
      buildingTenantId,
    });
    return response.data.repeatingEmails;
  } catch (error) {
    throwCaughtError(error);
  }
};
