import { HTMLAttributes } from "react";
import "./../../globals.scss";
import styles from "./icon.module.scss";

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  name: string;
  className?: string;
  large?: boolean;
  title?: string;
  style?: React.CSSProperties;
}

export function Icon({
  name,
  className,
  large,
  title,
  style,
  ...props
}: IconProps) {
  return (
    <span
      title={title}
      className={`icon-${name} ${className ? className : ""} ${
        large ? styles.large : styles.default
      } `}
      style={style}
      aria-hidden
      {...props}
    >
      {/* <span>'s are only used for multi-color glyphs and can cause undesired side-effects if included for other icons */}
      {name === "google_calendar" && (
        <>
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <span className="path4"></span>
          <span className="path5"></span>
          <span className="path6"></span>
          <span className="path7"></span>
          <span className="path8"></span>
        </>
      )}
    </span>
  );
}
