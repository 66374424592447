import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";
import { Building, ExtendedUser } from "@/types";
import { env } from "@/lib/utils";

const BuildingQueryFn = async () => {
  try {
    const response = await client.get("/getBuilding");
    return response.data;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const buildingOptions = {
  queryKey: ["building"],
  queryFn: BuildingQueryFn,
};

export const useBuilding = (): UseSuspenseQueryResult<Building> => {
  return useSuspenseQuery({ ...buildingOptions, staleTime: 1000 * 60 * 60 });
};

export const usePublicBuildingData =
  (): UseSuspenseQueryResult<Building | null> => {
    return useSuspenseQuery({
      queryKey: ["building", "public"],
      queryFn: async () => {
        try {
          const response = await fetch(
            `${env("VITE_API_BASE")}/api/getPublicBuilding`,
          );
          return await response.json();
        } catch (error) {
          console.error(error);
          return null;
        }
      },
    });
  };

export const buildingAdminsOptions = (userIds: string[] | undefined = []) => ({
  queryKey: ["building-admins", userIds],
  queryFn: async () => {
    try {
      const userIdsAsQueryParams = userIds
        .map((id, index) => (index === 0 ? `userIds=${id}` : `&userIds=${id}`))
        .join("");
      const response = await client.get(
        `/getBuildingAdmins?${userIdsAsQueryParams}`,
      );
      return response.data.users;
    } catch (error) {
      throwCaughtError(error);
    }
  },
});

export const useBuildingAdmins = (
  userIds: string[] | undefined = [],
): UseSuspenseQueryResult<ExtendedUser[]> => {
  return useSuspenseQuery(buildingAdminsOptions(userIds));
};

export const updateBuildingSettings = async (updateData: Partial<Building>) => {
  return await client.patch("/updateBuilding", { updateData });
};
