import "@/styles/index.scss";
import "./i18n/config.ts";
import styles from "@/styles/App.module.scss";
import { lazy, StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LoadingScreen from "@/components/LoadingScreen";
import ErrorBoundary from "./components/app/ErrorBoundary.tsx";
const RouterSetup = lazy(() => import("@/components/RouterSetup.tsx"));

import AuthProvider from "./providers/AuthProvider.tsx";

const queryClient = new QueryClient();

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary
        outsideAuthProvider
        className={styles.topLevelErrorBoundary}
      >
        <AuthProvider>
          <Suspense fallback={<LoadingScreen />}>
            <RouterSetup />
          </Suspense>
        </AuthProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  </StrictMode>,
);
