import {
  useQuery,
  UseQueryResult,
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import client, { throwCaughtError } from "../client";
import { CURR_VERSION } from "@/lib/constants";
import { env } from "@/lib/utils";

const isDevEnv = env("VITE_ENVIRONMENT") === "dev";

export const useVersionNumber = (): UseQueryResult<string> => {
  return useQuery({
    queryKey: ["version"],
    queryFn: async () => {
      if (isDevEnv) {
        return CURR_VERSION;
      }
      try {
        const response = await client.get("/getVersionNumber");
        return response.data;
      } catch (error) {
        console.error(error);
        return CURR_VERSION;
      }
    },
    refetchInterval: isDevEnv ? false : 1000 * 60 * 5, // 5 mins
  });
};

export const uploadImage = async (image: string, userName?: string) => {
  return await client.post("/uploadImage", {
    image,
    ...(userName ? { userName } : {}),
  });
};

export const handleUploadImage = async (
  encodedImage: string,
): Promise<string> => {
  try {
    const imgUpload = await uploadImage(encodedImage);
    return imgUpload.data.url;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const uploadFile = async (file: string, companyName: string) => {
  return await client.post("/uploadFile", {
    file,
    companyName,
  });
};

export const sendSMSMessage = async (
  recipientsPhoneNumber: string,
  messageBody: string,
  recipientLanguage?: string,
  senderName?: string,
  quickMessage?: boolean,
) => {
  return await client.post("/sendSms", {
    recipientsPhoneNumber,
    messageBody,
    recipientLanguage,
    senderName,
    quickMessage,
  });
};

export const getIntercomHMAC = async (): Promise<string | undefined> => {
  try {
    const response = await client.post("/createHmac");
    return response.data.hash;
  } catch (error) {
    throwCaughtError(error);
  }
};

export const getImageFunction = async (path: string) => {
  const response = await client.get(`/getImage?path=${path}`);
  return response.data.url || "";
};

export const getImageOptions = (path: string) => {
  return {
    queryKey: ["image", path],
    queryFn: () => getImageFunction(path),
  };
};

export const useImage = (path: string): UseSuspenseQueryResult<string> => {
  return useSuspenseQuery(getImageOptions(path));
};

export const getBlobFromUrl = async (url: string) => {
  try {
    const response = await client.get(`/getBlobFromUrl?url=${url}`, {
      responseType: "blob",
    });

    let fileExtension = "jpeg";
    const contentType = response.headers["content-type"];
    if (contentType.split("/")[0] === "image") {
      fileExtension = contentType.split("/")[1];
      if (fileExtension.includes("+")) {
        fileExtension = fileExtension.split("+")[0];
      }
    }
    const blob = new Blob([response.data], { type: contentType });
    return { blob, fileExtension };
  } catch (error) {
    throwCaughtError(error);
  }
};
