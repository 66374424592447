import { Event } from "react-big-calendar";
import { MEETING_ROOM_FEATURES } from "./lib/constants";
import { AxiosError } from "axios";
import { ReactNode } from "react";

export type CustomAxiosError = AxiosError<{ code: string }>;

export type DateString = `${string}-${string}-${string}` & {
  __brand: "DateString";
};
export type TimeString = `${string}:${string}` & { __brand: "TimeString" };

export type DropdownOption = {
  value: string;
  label: string;
};

export type MeetingRoomFeature = DropdownOption & {
  icon: string;
};

export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
}

export type storeType = {
  notifications: {
    id: number;
    message: string | ReactNode;
    type: NotificationType;
  }[];
  addNotification: (
    message: string | ReactNode,
    type?: NotificationType,
    persistent?: boolean,
  ) => void;
  removeNotification: (id: number) => void;
};

export type userStoreType = {
  userName: string;
  setUserName: (userName: string) => void;
};

export type contextStoreType = {
  selectedCompany: SelectChild | undefined;
  setCompany: ({ value, label }: SelectChild) => void;
};

export type Timestamp = {
  _seconds: number;
  _nanoseconds: number;
};

export interface CompanyVisit {
  id: string;
  entranceId: string;
  entranceName: string;
  buildingId: string;
  buildingTenantId: string;
  buildingTenantName: string;
  language: string; //host language
  messageFromVisitor: string;
  visitorPhone: string;
  visitorCompanyName: string;
  visitorId: string;
  visitorName: string;
  visitorLanguage?: string;
  createdAt: Timestamp;
  whoReplied?: string;
  letInAt?: Timestamp;
  messageHistory?: MessageHistoryItem[];
  errorSendingAccessCode?: boolean;
}

export interface Visit extends CompanyVisit {
  hostId: string;
  hostPhone: string;
  hostName: string;
  hostEmail: string;
}

export interface MessageHistoryItem {
  senderId: string;
  senderName: string;
  body: string;
  timestamp: Timestamp;
}

type PropertyManager = {
  firstName: string;
  lastName: string;
  managerProfileImgUrl: string;
};

export enum Mode {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export enum AccessSystem {
  STARWATCH = "starwatch",
}

export enum MenuItemVisibility {
  USER = "user",
  COMPANY_ADMIN = "company_admin",
}

export type CustomMenuItem = {
  url: string;
  label: {
    en: string;
    no: string;
  };
  visibility: MenuItemVisibility;
};

export type Address = {
  street: string;
  postalCode: string;
  city: string;
  country: string;
};

export type Building = {
  propertyManager: PropertyManager;
  buildingBannerImgUrl: string;
  buildingAdmins: string[];
  displayName: string;
  legalName: string;
  address?: Address;
  owner: string;
  ownerLogoImgUrl: string;
  defaultAccessCardPickupMsg?: {
    no: string;
    en: string;
  };
  bookingEnabled?: boolean;
  bookingVisibleForUsers?: boolean;
  bookingSettings?: {
    maxBookingsPerPerson?: number;
    maxDaysAhead?: number;
    maxDuration?: number;
  };
  accessMode?: Mode;
  accessSystem?: AccessSystem;
  buildingAdminsNotifications?: string[];
  accessControlSystemIntegration?: boolean; // If the building has an access control system integration with Sharry
  customMenuItems?: CustomMenuItem[];
};

// Building + fields for Base64 encoded images
export type BuildingForm = Omit<Building, "buildingAdmins"> & {
  propertyManager?: PropertyManager & {
    managerProfileImg?: string;
  };
  buildingBannerImg: string;
  ownerLogoImg: string;
};

export type Entrance = {
  entranceId?: string;
  entranceName: {
    no: string;
    en: string;
  };
  address: string;
};

export interface User {
  buildingTenantId: string;
  adminInCompanies: string[];
  email: string;
  phone: string;
  customEmployeeId?: string;
  userId: string;
  firstName: string;
  lastName: string;
  visibleInVisit: boolean;
  language: string;
  generalConsent: boolean;
  userActive?: boolean;
  userProfileImgUrl?: string;
  companyContact?: boolean;
  activeAccessCardNumber?: string | null;
  notificationPreferences?: { sms: boolean; email: boolean };
  integratedWithAccessControlSystem?: boolean;
  createdAt?: Date;
  lastLogin?: Date;
}

export interface ExtendedUser extends User {
  buildingTenantName: string;
}

export interface CompanyContact {
  userId: string;
  name: string;
  email: string;
}

export enum ConsentType {
  GENERAL = "general",
}

export type Consent = {
  id?: string;
  type: ConsentType;
  userId: string;
  consented: boolean;
  timestamp: Timestamp;
};

export enum VisitMode {
  ENABLED = "enabled",
  LIMITED = "limited",
  DISABLED = "disabled",
}

export type SSOItem = {
  provider: string;
  domain: string;
};

export interface Company {
  buildingTenantId: string;
  buildingTenantName: string;
  entranceIds: string[];
  floor?: string;
  companyLogoImgUrl?: string;
  orgNumber: string;
  ssoSettings: SSOItem[];
  visitMode: VisitMode;
  sso: boolean;
  customLetInMessage?: string;
  accessControlSystemIntegration?: boolean; // If the company has an access control system integration with Sharry
  bookingDiscountMultiplier?: number;
}

export interface ExtendedCompany extends Company {
  totalUsers: number;
  activeUsers: number;
}

export type SelectChild = {
  value: string;
  label: string;
};

export type AddUserType = {
  firstName: string;
  lastName: string;
  buildingTenantId: string;
  adminInCompanies: string[];
  email: string;
  phone?: string;
  customEmployeeId?: string;
  visibleInVisit: boolean;
  companyContact?: boolean;
  sso?: boolean;
  sendEmail: boolean;
};

export type CsvData = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};

export type BronnoysundCompanyResult = {
  organisasjonsnummer: string;
  navn: string;
};

export enum CardType {
  PHYSICAL = "physical",
  DIGITAL = "digital",
}

export enum CardStatus {
  ORDERED = "ordered",
  ACTIVE = "active",
  INACTIVE = "inactive",
  DEACTIVATED = "deactivated",
  CANCELLED = "cancelled", // order cancelled
}

export type CardOrderedBy = {
  userId: string;
  company: string;
  name?: string;
  email?: string;
};

export type AccessCardType = {
  id: string;
  type: CardType;
  orderedBy: CardOrderedBy;
  orderNote?: string;
  status: CardStatus;
  createdAt: Timestamp;
  activatedAt?: Timestamp;
  inactivatedAt?: Timestamp;
  deactivatedAt?: Timestamp;
  cancelledAt?: Timestamp;
  cardNumber?: string;
  pin?: string;
  userId: string;
  userName?: string;
  pickupInstructions?: string;
  reasonForDeactivation?: string;
  note?: string;
};

export type AccessCardHistory = {
  cardId: string;
  status: CardStatus;
  timestamp: Timestamp;
  modifiedByUserId: string;
};

export type PreRegistrationAttendee = {
  name: string;
  phone: string;
  email?: string;
  arrivedAt?: Timestamp;
  errorSendingAccessCode?: boolean;
  checkedInById?: string;
  checkedInByName?: string;
};

export type PreRegistrationGroup = {
  id: string;
  hostId: string;
  hostName?: string;
  hostPhone?: string;
  hostEmail: string;
  companyName: string;
  companyId: string;
  eventName: string;
  eventDescription?: string;
  startTime: Timestamp;
  attendees: PreRegistrationAttendee[];
  entranceId: string;
};

export type ExtendedPreRegistrationAttendee = {
  name: string;
  phone: string;
  email?: string;
  arrivedAt?: Timestamp;
  startTime: Timestamp;
  hostName?: string;
  hostPhone?: string;
  hostEmail: string;
  eventName: string;
  eventDescription?: string;
  companyName: string;
  companyId: string;
  eventId: string;
  errorSendingAccessCode?: boolean;
  checkedInById?: string;
  checkedInByName?: string;
};

export type Visitor = Visit | ExtendedPreRegistrationAttendee;

export enum BookableResourceType {
  MEETING_ROOM = "MEETING_ROOM",
  CONFERENCE_ROOM = "CONFERENCE_ROOM",
}

export type BookableResource = {
  roomId: string;
  roomName: string;
  description: {
    en: string;
    no: string;
  };
  type: BookableResourceType;
};

export type MeetingRoomFeatures = (keyof typeof MEETING_ROOM_FEATURES)[];

export enum MeetingRoomPricingModel {
  FREE = "free",
  HOURLY = "hourly",
}

export enum MeetingRoomBookingPermission {
  ALL = "all",
  COMPANY_ADMIN = "company_admin",
  BUILDING_ADMIN = "building_admin",
}

export type MeetingRoom = BookableResource & {
  googleResourceId: string;
  floor: string | number;
  location?: {
    en: string;
    no: string;
  };
  features: MeetingRoomFeatures;
  capacity: number;
  thumbnail?: string;
  images?: string[];
  hourlyPrice?: number;
  opensAt?: TimeString;
  closesAt?: TimeString;
  closedOnDays?: number[];
  meetingFoodUrl?: string;
  allowBookingFor: MeetingRoomBookingPermission;
  hideEventTitles: boolean;
};

export enum MeetingRoomBookingStatus {
  CONFIRMED = "confirmed",
  CANCELLED = "cancelled",
}

export type MeetingRoomBooking = {
  userId: string;
  id: string;
  google_event_url: string;
  companyId: string;
  status: MeetingRoomBookingStatus;
  buildingId: string;
  roomId: string;
  roomName: string;
  event: {
    title: string;
    start: Date;
    end: Date;
  };
};

export enum BookingStatus {
  CONFIRMED = "confirmed",
  CANCELLED = "cancelled",
}

export interface CalendarEvent extends Event {
  isUsersBooking?: boolean;
}

export interface BuildingAdminEvent extends Event {
  bookingId: string;
  userId: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  companyId: string;
  companyName: string;
  roomId: string;
  roomName: string;
  status: BookingStatus;
  hourlyPrice?: number;
  companyDiscount?: number;
  totalPrice?: number;
}

export type AccessRole = {
  id: string;
  name: string;
  default: boolean;
};

export type AccessCardsNew = {
  cardId: string;
  badgeId: string;
  type: CardTypeNew;
  subtype?: CardSubtype;
  state: CardStateNew;
  cardNumber: string;
  deviceType?: VirtualCardDeviceType;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};

export enum VirtualCardDeviceType {
  MOBILE = "mobile",
  WATCH = "watch",
}

export enum CardTypeNew {
  PLASTIC = "plastic",
  VIRTUAL = "virtual",
}

export enum CardSubtype {
  DEFAULT = "default",
  APPLE_WALLET = "apple-wallet",
}

export enum CardStateNew {
  ACTIVE = "active",
  DEACTIVATED = "deactivated",
  INACTIVE = "inactive",
  AVAILABLE = "available", //Should never happend for user (available cards are not attached to a particular user)
  ACTIVATING = "activating",
  DEACTIVATING = "deactivating",
}
